import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSwitchNetwork } from "wagmi";
import { selectedChainSelector } from "../../../store/reducers/transfer";
import PurpleButton from "../misc/PurpleButton";
import { ToastContainer, toast } from 'react-toastify';

export default function SwitchNetworkButton({ chain }: any) {
  const { switchNetwork, isLoading: isSwitchingNetwork, error } = useSwitchNetwork()

  useEffect(() => {
    if (error) {
      toast.error('Failed to switch to the requested network, please check your wallet for details.')
    }
  }, [error])

  if (switchNetwork) {
    return (
      <PurpleButton disabled={isSwitchingNetwork} onClick={() => switchNetwork(parseInt(chain.chainId))}>
        {isSwitchingNetwork ?
          <>Switching to the {chain.name} network</>
        :
          <>Switch to the {chain.name} network</>
        }
      </PurpleButton>
    )
  }

  return (
    <PurpleButton disabled={true}>
      Please switch to the {chain.name} network
    </PurpleButton>
  )
}