import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { selectToken, toggleTokenModal } from "../../store/reducers/transfer";
import { selectWithdrawalToken } from "../../store/reducers/withdraw";
import { useAccount, useBalance } from "wagmi";
import Loader from "./Loader";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.2);
`

const TokenModalContainer = styled.div`
  width: 500px;
  height: 600px;
  position: fixed;
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 0 8px 24px rgb(0 0 0 / 8%);
  border-radius: 12px;
  z-index: 5;
  overflow-y: scroll;

  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`

const TokenModalBox = styled.div`
  background-color: var(--gray-100);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const TokenModalHeader = styled.header`
  padding: 32px 32px 0;
`

const TokenModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TokenModalHeaderTitle = styled.h1`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  color: var(--gray-70);
  display: flex;
  align-items: center;
`

const TokenModalCloseButton = styled.button`
  outline: none;
  border: none;
  font-family: inherit;
  background-color: unset;
  cursor: pointer;
`

const TokenModalHeaderSeparator = styled.hr`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #dddfe0;
  margin: 16px 0 0;
  border: none;
`

const TokenListContainer = styled.main`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto!important;
`

const TokenList = styled.ul`
  list-style: none;
  margin: 16px 0 0;
  padding: 0 32px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
  }
`

const Token = styled.li`
  --row-min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5rem;
  cursor: pointer;
  padding: 12px;
  border-radius: 16px;
  position: relative;

  &:hover {
    background-color: #f7f7f8;
  }
`

const TokenDetails = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
`

const TokenBalance = styled.div`
  font-size: .875rem;
  line-height: 1.25;
`

const TokenLogoContainer = styled.div`
  position: relative;
  height: 2.5rem;
`

const TokenLogo = styled.img`
  height: 2.5rem;
  width: 2.5rem;

  @media only screen and (max-width: 768px) {
    height: 1.5rem;
    width: 1.5rem;
  }
`

const TokenNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 2.5rem;
`

const TokenSymbol = styled.span`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.25;
`

const TokenName = styled.div`
  max-width: 16rem;
`

const TokenName2 = styled.span`
  font-size: .875rem;
  color: #292c34;
  max-width: 200px;
`

const showTokenModalSelector = (state: any) => state.transfer.showTokenModal

function TokenItem({ token, chainId, selectTokenCallback }: any) {
  const { address, connector, isConnected } = useAccount()
  const balance = useBalance({
    address,
    chainId: parseInt(chainId),
    token: token.contractAddress,
    watch: true
  })

  return (
    <Token key={token.id} onClick={() => selectTokenCallback(token.id)}>
      <TokenDetails>
        <TokenLogoContainer>
          <TokenLogo src={token.image} />
        </TokenLogoContainer>
        <TokenNameContainer>
          <TokenSymbol>{token.id}</TokenSymbol>
          <TokenName>
            <TokenName2>{token.name}</TokenName2>
          </TokenName>
        </TokenNameContainer>
      </TokenDetails>
      <TokenBalance>
        {balance.isFetching ?
          <Loader color="purple" height="8px" />
        :
          <>{balance.data?.formatted}</>
        }
      </TokenBalance>
    </Token>
  )
}

export default function TokenModal({ flow, chain }: any) {
  const dispatch = useDispatch<any>();
  const toggleModal = useCallback(() => {
    dispatch(toggleTokenModal())
  }, []);
  const showTokenModal = useSelector(showTokenModalSelector);
  const selectTokenCallback = useCallback(async (tokenId: string) => {
    if (flow === 'deposit') {
      dispatch(selectToken({
        tokenId
      }))
    } else {
      dispatch(selectWithdrawalToken({
        tokenId
      }))
    }
    
    dispatch(toggleTokenModal())
  }, [])

  if (!showTokenModal) {
    return null
  }

  return (
    <ModalContainer>
      <TokenModalContainer>
        <TokenModalBox>
          <TokenModalHeader>
            <TokenModalHeaderContainer>
              <TokenModalHeaderTitle>Select a token on Ethereum</TokenModalHeaderTitle>
              <TokenModalCloseButton onClick={toggleModal}>
                <img src="/images/close-black.svg" />
              </TokenModalCloseButton>
            </TokenModalHeaderContainer>
          </TokenModalHeader>
          <TokenModalHeaderSeparator />
        </TokenModalBox>
        <TokenListContainer>
          <TokenList>
            {chain.tokens.map((token: any) => {
              return (
                <TokenItem key={token.id} token={token} chainId={chain.chainId} selectTokenCallback={selectTokenCallback} />
              )
            })}
          </TokenList>
        </TokenListContainer>
      </TokenModalContainer>
    </ModalContainer>
  )
}