import { configureStore } from '@reduxjs/toolkit';
import transferReducer from './reducers/transfer';
import withdrawReducer from './reducers/withdraw';

const store = configureStore({
  reducer: {
    transfer: transferReducer,
    withdraw: withdrawReducer
  }
})

export default store;