import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useConnect } from "wagmi";
import WalletButton from "../Login/WalletButton";
import WalletConnect from "../Login/WalletConnect";

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;

  h2 {
    font-weight: 600;
    font-size: 1.125rem;
  }
`;

const Networks = styled.div`
  padding: 20px;
`;

const NoWallet = styled.div`
  border-top: 1px solid #e7e9ee;
  padding: 32px 10px;
  color: #9fa8ba;
  margin-top: 32px;
  text-align: center;
  font-size: .875rem;
`

export default function Connect() {
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect()

  return (
    <div>
      <Title>
        <h2>Connect your wallet</h2>
      </Title>
      <Networks>
        {connectors.map((connector) => (
          <WalletButton
            disabled={!connector.ready}
            key={connector.id}
            connect={() => connect({ connector })}
            label={connector.name}
            image={connector.id}
          />
        ))}
      </Networks>
      <NoWallet>
        Don't have a wallet? Download it <a href="https://metamask.io/" target="_blank">here</a>.
      </NoWallet>
    </div>
  )
}