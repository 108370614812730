import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useWaitForTransaction } from "wagmi";
import { ourChainSelector } from "../../store/reducers/transfer";
import { setWithdrawalStatus, withdrawalHashSelector, withdrawalStatusSelector } from "../../store/reducers/withdraw";
import WithdrawPending from "./WithdrawPending";
import WithdrawStart from "./WithdrawStart";
import WithdrawSuccess from "./WithdrawSuccess";

export default function Withdraw() {
  const dispatch = useDispatch()
  const ourChain = useSelector(ourChainSelector)
  const withdrawalHash = useSelector(withdrawalHashSelector)
  const withdrawalStatus = useSelector(withdrawalStatusSelector)
  const withdrawalTransaction = useWaitForTransaction({
    chainId: parseInt(ourChain.chainId),
    hash: withdrawalHash
  })

  useEffect(() => {
    dispatch(setWithdrawalStatus(withdrawalTransaction.status))
  }, [withdrawalTransaction.status])

  if (withdrawalStatus === 'success') {
    return (
      <WithdrawSuccess />
    )
  }

  if (withdrawalHash) {
    return (
      <WithdrawPending />
    )
  }

  return (
    <WithdrawStart />
  )
}