import React from 'react';
import styled from 'styled-components';
import { Provider } from 'react-redux';
import store from './store/store'
import './App.css';
import Header from './components/Header';
import Bridge from './components/Bridge/Bridge';
import TokenModal from './components/Bridge/TokenModal';
import { chain, Chain, configureChains, createClient, defaultChains, WagmiConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import ourChainData from './assets/chain.json';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

window.Buffer = window.Buffer || require("buffer").Buffer;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;

  @media only screen and (max-width: 768px) {
    width: 99%;
  }
`;

const ourChain: any = ourChainData
const metalChain: Chain = {
  id: parseInt(ourChain.chainId),
  name: ourChain.name,
  network: 'metal',
  nativeCurrency: {
    decimals: 18,
    name: 'Metal',
    symbol: 'METAL',
  },
  rpcUrls: {
    default: ourChain.api,
  },
  blockExplorers: {
    default: { name: 'MetalScan', url: ourChain.explorerUrl },
  },
  testnet: false,
}

const { chains, provider, webSocketProvider } = configureChains(
  [chain.goerli, metalChain],
  [
    infuraProvider({
      apiKey: "61521c20153e4c8abc61e2017b4a3f39"
    }),
    jsonRpcProvider({
      rpc: (chain: any) => {
        if (chain.id !== metalChain.id) return null
        return { http: chain.rpcUrls.default }
      },
    }),
  ]
)

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true
      }
    }),
    new CoinbaseWalletConnector({
      options: {
        appName: 'bridge.metalblockchain.org'
      }
    }),
    new InjectedConnector({
      chains
    })
  ],
  provider,
  webSocketProvider,
})

function App() {
  return (
    <Provider store={store}>
      <WagmiConfig client={client}>
        <AppContainer>
          <Header />
          <Content>
            <Bridge />
          </Content>
        </AppContainer>
        
      </WagmiConfig>
      <ToastContainer />
    </Provider>
  );
}

export default App;
