import { useSelector } from "react-redux";
import { useSwitchNetwork } from "wagmi";
import { selectedChainSelector } from "../../../store/reducers/transfer";
import PurpleButton from "../misc/PurpleButton";

export default function SwitchNetworkButton() {
  const selectedChain = useSelector(selectedChainSelector)
  const { switchNetwork, isLoading: isSwitchingNetwork } = useSwitchNetwork()

  if (switchNetwork) {
    return (
      <PurpleButton disabled={isSwitchingNetwork} onClick={() => switchNetwork(parseInt(selectedChain.chainId))}>
        {isSwitchingNetwork ?
          <>Switching to the {selectedChain.name} network</>
        :
          <>Switch to the {selectedChain.name} network</>
        }
      </PurpleButton>
    )
  }

  return (
    <PurpleButton disabled={true}>
      Please switch to the {selectedChain.name} network
    </PurpleButton>
  )
}