import { useDisconnect } from "wagmi";
import styled from 'styled-components';

const Button = styled.div`
  outline: 0;
  margin-top: 15px;
  font-size: 0.875rem;
  cursor: pointer;
`

export default function DisconnectButton() {
  const { disconnect } = useDisconnect()

  return (
    <Button onClick={() => disconnect()}>Disconnect</Button>
  )
}