import React from 'react';
import styled from 'styled-components';
import Logo from '../images/logo.svg';

const TopNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  & img {
    width: 200px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

export default function Header() {
  return (
    <TopNav>
      <img src={Logo} />
    </TopNav>
  )
}