import React, { useCallback, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ourChainSelector, selectedChainSelector, selectedTokenSelector, setTransferAmount, toggleTokenModal } from '../../../store/reducers/transfer';
import Loader from '../Loader';
import BN from 'bignumber.js';

const ChainBalance = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const ChainBalance1 = styled.span`
  font-size: .75rem;
  font-weight: 500;
  color: #b0b4bb;
  padding-right: 8px;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

const ChainBalance2 = styled.span`
  font-size: .75rem;
  font-weight: 600;
  color: black;
  padding-left: 4px;

  @media only screen and (max-width: 768px) {
    text-align: right;
    padding-left: 0;
  }
`

const ToBox = styled.div`
  background: #f7f7f8;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
`

const ToBoxChainContainer = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
`

const ToChainImage = styled.img`
  margin-right: 9px;
  height: 24px;
  width: 24px;
`

export default function TargetNetwork() {
  const { address, connector, isConnected } = useAccount()
  const selectedToken = useSelector(selectedTokenSelector)
  const ourChain = useSelector(ourChainSelector)
  const targetToken = ourChain.tokens.find((c: any) => c.id === selectedToken.id);
  const targetChainBalance = useBalance({
    address,
    chainId: parseInt(ourChain.chainId),
    token: targetToken.contractAddress,
    watch: true
  })

  return (
    <ToBox>
      <ToBoxChainContainer>
        <ToChainImage src="/images/assets/metal.svg" />
        <span>Metal Blockchain</span>
      </ToBoxChainContainer>
      <ChainBalance>
          <ChainBalance1>Balance:</ChainBalance1>
          <ChainBalance2>{targetChainBalance.data?.formatted} {selectedToken.id}</ChainBalance2>
        </ChainBalance>
    </ToBox>
  )
}