import React, { useCallback, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { amountSelector, selectedChainSelector, selectedTokenSelector, setTransferAmount, toggleTokenModal } from '../../../store/reducers/transfer';
import Loader from '../Loader';
import BN from 'bignumber.js';

const FromBox = styled.div`
  border: 1px solid #dddfe0;
  border-radius: 8px;
`

const ChainBalanceContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddfe0;
  display: flex;
  padding: 16px;
`

const ChainBalanceChain = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;

  img {
    margin-right: 9px;
  }
`

const ChainInputContainer = styled.div`
  display: flex;
  width: 100%;
`

const ChainInputButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid hsla(0,0%,100%,.1);
  border-right: 1px solid #dddfe0;
  border-radius: 0 0 0 12px;
  padding: 20px 16px;
  cursor: pointer;
  margin: auto 0;
  background-color: transparent;
  outline: 0;
`

const ChainInputButtonImage = styled.img`
  height: 20px;
  width: 20px;
`

const ChainInputButtonAssetName = styled.span`
  margin: 0 8px;
`

const ChainInputButtonArrowDown = styled.img`
  height: 24px;
  width: 24px;
`

const ChainInputAmountContainer = styled.div`
  display: flex;
  width: 100%;
`

const ChainInputAmountInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 0;
  font-weight: 600;
  letter-spacing: normal;
  display: flex;
  outline: 0;
  font-size: .875rem;
  line-height: 24px;
  padding: 10px 16px;
`

const ChainInputAmountMaxButton = styled.button`
  font-weight: 400;
  letter-spacing: normal;
  font-size: .875rem;
  line-height: 20px;
  padding: 7px 20px;
  display: inline-flex;
  outline: 0;
  align-items: center;
  height: 100%;
  border-radius: 0 0 12px 0;
  color: #dddfe0;
  border: 1px solid hsla(0,0%,100%,.1);
  background-color: transparent;
  cursor: pointer;
`

const ChainBalance = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const ChainBalance1 = styled.span`
  font-size: .75rem;
  font-weight: 500;
  color: #b0b4bb;
  padding-right: 8px;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

const ChainBalance2 = styled.span`
  font-size: .75rem;
  font-weight: 600;
  color: black;
  padding-left: 4px;

  @media only screen and (max-width: 768px) {
    text-align: right;
    padding-left: 0;
  }
`

export default function SourceNetwork() {
  const dispatch = useDispatch();
  const { address, connector, isConnected } = useAccount()
  const selectedChain = useSelector(selectedChainSelector)
  const selectedToken = useSelector(selectedTokenSelector)
  const sourceChainBalance = useBalance({
    address,
    chainId: parseInt(selectedChain.chainId),
    token: selectedToken.contractAddress,
    watch: true
  })
  const toggleModal = useCallback(() => {
    dispatch(toggleTokenModal())
  }, [])
  const previousAmount = useSelector(amountSelector)
  const [amount, setAmount] = useState(previousAmount)
  const updateAmount = useCallback((value: any) => {
    setAmount(value)
    dispatch(setTransferAmount(value))
  }, [amount, sourceChainBalance])
  const setMaxCallback = useCallback(() => {
    updateAmount(sourceChainBalance.data?.formatted)
  }, [sourceChainBalance])

  return (
    <FromBox>
      <ChainBalanceContainer>
        <ChainBalanceChain>
          <img src={selectedChain.image} />
          <span>{selectedChain.name}</span>
        </ChainBalanceChain>
        <ChainBalance>
          <ChainBalance1>Balance:</ChainBalance1>
          {sourceChainBalance.isFetching ?
            <Loader color="purple" height="8px" />
          :
            <ChainBalance2>{sourceChainBalance.data?.formatted} {selectedToken.id}</ChainBalance2>
          }
        </ChainBalance>
      </ChainBalanceContainer>
      <ChainInputContainer>
        <ChainInputButton onClick={toggleModal}>
          <ChainInputButtonImage src={selectedToken.image} />
          <ChainInputButtonAssetName>{selectedToken.id}</ChainInputButtonAssetName>
          <ChainInputButtonArrowDown src="/images/discloser-bottom.svg" />
        </ChainInputButton>
        <ChainInputAmountContainer>
          <ChainInputAmountInput value={amount} onChange={(e: any) => updateAmount(e.target.value)} />
          <ChainInputAmountMaxButton onClick={setMaxCallback}>MAX</ChainInputAmountMaxButton>
        </ChainInputAmountContainer>
      </ChainInputContainer>
    </FromBox>
  )
}