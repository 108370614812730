import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import react, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

const Container: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 32px;
  padding-right: 32px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: ${(props: any) => props.available ? "1" : "0.7"}
`

const Container2 = styled.div`
  border: 1px solid #f3f4f7;
  border-radius: 8px;
  padding: 20px;
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
`

const Container3 = styled.div`
  display: flex;
`

const LogoContainer = styled.div`
  display: flex;
  align-self: center;
  border-radius: 50%;

  img {
    width: 56px;
    height: 56px;
  }
`

const TextContainer = styled.div`
  padding-left: 20px;
  text-align: left;
  align-self: center;
  flex-direction: column;
  display: flex;
`

const Title = styled.span`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
`

const SubTitle = styled.span`
  font-size: .875rem;
  color: #6e798f;
`

const ArrowContainer = styled.div`
  display: flex;
  align-self: center;
  margin-left: auto;
`

const UnavailableLabel = styled.span`
  color: #FC9237;
  font-size: 12px;
  margin-top: 5px;
`

export default function WalletButton({ connect, image, label, disabled }: any) {
  return (
    <Container onClick={connect} available={!disabled}>
      <Container2>
        <Container3>
          <LogoContainer>
            <img src={`/images/${image}.svg`} />
          </LogoContainer>
          <TextContainer>
            <Title>{label}</Title>
            <SubTitle>Connect using {label} wallet</SubTitle>
            {disabled &&
              <UnavailableLabel>Wallet is not available in this browser</UnavailableLabel>
            }
          </TextContainer>
          <ArrowContainer>
            <FontAwesomeIcon icon={solid('arrow-right')} />
          </ArrowContainer>
        </Container3>
      </Container2>
    </Container>
  )
}