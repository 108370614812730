import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import chainData from '../../assets/tokens.json';
import chain from '../../assets/chain.json';

const chains: any = chainData;
const ourChain: any = chain;
const initialState: any = {
  selectedChain: chains[Object.keys(chains)[0]],
  ourChain: ourChain,
  selectedToken: chains[Object.keys(chains)[0]].tokens[0],
  showTokenModal: false,
  amount: "0.00",
  depositHash: null,
  depositStatus: null
}

export const amountSelector = createSelector((state: any) => state,
  (state) => state.transfer.amount
);
export const selectedChainSelector = createSelector((state: any) => state,
  (state) => state.transfer.selectedChain
);
export const ourChainSelector = createSelector((state: any) => state,
  (state) => state.transfer.ourChain
);
export const selectedTokenSelector = createSelector((state: any) => state,
  (state) => state.transfer.selectedToken
);
export const depositHashSelector = createSelector((state: any) => state,
  (state) => state.transfer.depositHash
);
export const depositStatusSelector = createSelector((state: any) => state,
  (state) => state.transfer.depositStatus
);

export const transferSlice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    selectDefaultChain(state) {
      state.selectedChain = chains[Object.keys(chains)[0]]
    },
    selectDefaultDepositToken(state) {
      state.selectedToken = state.selectedChain.tokens[0]
    },
    selectToken(state, action: any) {
      return {
        ...state,
        selectedToken: state.selectedChain.tokens.find((token: any) => token.id === action.payload.tokenId)
      }
    },
    toggleTokenModal(state) {
      return {
        ...state,
        showTokenModal: !state.showTokenModal
      }
    },
    setTransferAmount(state, action: any) {
      state.amount = action.payload;
    },
    resetDeposit(state) {
      return initialState
    },
    setDepositHash(state, action: any) {
      state.depositHash = action.payload;
    },
    setDepositStatus(state, action: any) {
      state.depositStatus = action.payload;
    }
  }
})

export const { selectDefaultChain, selectDefaultDepositToken, selectToken, toggleTokenModal, setTransferAmount, resetDeposit, setDepositHash, setDepositStatus } = transferSlice.actions;
export default transferSlice.reducer;