import ProtonWebSDK from '@proton/web-sdk';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import chainData from '../../assets/tokens.json';
import chain from '../../assets/chain.json';
import fetch from 'node-fetch';
import { BigNumber, ethers, utils } from 'ethers';
import Bluebird from 'bluebird';

const chains: any = chainData;
const ourChain: any = chain;
const initialState: any = {
  selectedToken: ourChain.tokens[0],
  withdrawalHash: null,
  withdrawalStatus: null
}

export const selectedWithdrawalTokenSelector = createSelector((state: any) => state,
  (state) => state.withdraw.selectedToken
);
export const withdrawalHashSelector = createSelector((state: any) => state,
  (state) => state.withdraw.withdrawalHash
);
export const withdrawalStatusSelector = createSelector((state: any) => state,
  (state) => state.withdraw.withdrawalStatus
);

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    selectDefaultWithdrawalToken(state) {
      state.selectedToken = ourChain.tokens[0]
    },
    selectWithdrawalToken(state, action: any) {
      state.selectedToken = ourChain.tokens.find((token: any) => token.id === action.payload.tokenId)
    },
    resetWithdrawal() {
      return initialState
    },
    setWithdrawalHash(state, action) {
      return {
        ...state,
        withdrawalHash: action.payload
      }
    },
    setWithdrawalStatus(state, action) {
      return {
        ...state,
        withdrawalStatus: action.payload
      }
    }
  }
})

export const { selectDefaultWithdrawalToken, selectWithdrawalToken, resetWithdrawal, setWithdrawalHash, setWithdrawalStatus } = withdrawSlice.actions;
export default withdrawSlice.reducer;