import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import Lottie from "lottie-react";
import loadingCrypto from '../../lotties/loading-crypto.json'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoadingContainer = styled.div`
  height: 150px;
  width: 150px;
`

export default function DepositPending() {
  return (
    <Container>
      <h2>Please wait...</h2>
      <LoadingContainer>
        <Lottie animationData={loadingCrypto} />
      </LoadingContainer>
      <p>We're currently confirming your transaction.</p>
    </Container>
  )
}