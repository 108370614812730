import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useAccount } from "wagmi";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";

const Container = styled.div`
  padding: 20px;
`

const AddressContainer = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f8;
  border-radius: 12px;

  img {
    height: 24px;
    padding-right: 20px;
  }

  h2 {
    font-weight: 600;
    font-size: .875rem;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    h2 {
      font-size: 12px;
    }

    img {
      padding-right: 0;
    }
  }
`

const NavContainer = styled.nav`
  display: flex;
  background: hsla(0,0%,100%,.4);
  background-color: #f7f7f8;
  padding: 4px;
  position: relative;
  border-radius: 12px;
  margin-bottom: 12px;
`

const NavButton: any = styled.button`
  width: 100%;
  justify-content: center;
  border: none;
  z-index: 1;
  display: inline-flex;
  outline: 0;
  cursor: pointer;
  margin: auto 0;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  padding: 10px 20px;
  background-color: transparent;
  appearance: none;

  &.active {
    background-color: white;
  }
`

const address = (state: any) => state.auth.metaMask.address

export default function Connected() {
  const dispatch = useDispatch<any>()
  const [transferType, setTransferType] = useState("deposit");
  const { address, connector, isConnected } = useAccount()

  return (
    <Container>
      <NavContainer>
        <NavButton onClick={() => setTransferType('deposit')} className={transferType == 'deposit' ? "active" : ""}>Deposit</NavButton>
        <NavButton onClick={() => setTransferType('withdraw')} className={transferType == 'withdraw' ? "active" : ""}>Withdraw</NavButton>
      </NavContainer>
      <AddressContainer>
        <img src="/images/metamask2.svg" />
        <h2>{address}</h2>
      </AddressContainer>
      {transferType === 'deposit' &&
        <Deposit />
      }
      {transferType === 'withdraw' &&
        <Withdraw />
      }
    </Container>
  )
}