import { useDispatch, useSelector } from "react-redux"
import { useAccount, useContractWrite, useNetwork, usePrepareContractWrite, useSwitchNetwork } from "wagmi"
import { amountSelector, ourChainSelector, selectedChainSelector, selectedTokenSelector, setDepositHash } from "../../../store/reducers/transfer"
import styled from 'styled-components';
import { forwardingContractABI } from "../../../contracts/forwadingContract";
import { ethers } from "ethers";
import { useEffect } from "react";
import PurpleButton from "../misc/PurpleButton";
import SwitchNetworkButton from "../misc/SwitchNetworkButton";
import { selectedWithdrawalTokenSelector, setWithdrawalHash } from "../../../store/reducers/withdraw";
import { withdrawalContractABI } from "../../../contracts/withdrawalContract";

export default function TransferButton({ isAmountValid }: any) {
  const ourChain = useSelector(ourChainSelector)
  const targetChain = useSelector(selectedChainSelector)
  const selectedToken = useSelector(selectedWithdrawalTokenSelector)
  const amount = useSelector(amountSelector)
  const { chain: connectedChain } = useNetwork()
  const { address } = useAccount()
  const parsedAmount = ethers.utils.parseUnits(amount || "0", parseInt(selectedToken.precision))
  const tokenAssetTransfer = usePrepareContractWrite({
    address: ourChain.forwardingContract,
    abi: withdrawalContractABI,
    functionName: 'withdraw',
    args: [selectedToken.contractAddress, parsedAmount.toString(), address, targetChain.chainId],
    chainId: parseInt(ourChain.chainId)
  })
  const tokenAssetTransferWrite = useContractWrite({
    ...tokenAssetTransfer.config
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (tokenAssetTransferWrite.data?.hash) {
      dispatch(setWithdrawalHash(tokenAssetTransferWrite.data.hash))
    }
  }, [tokenAssetTransferWrite.data])

  if(parseInt(ourChain.chainId) !== connectedChain?.id) {
    return <SwitchNetworkButton chain={ourChain} />
  }

  return (
    <PurpleButton disabled={!isAmountValid || !tokenAssetTransferWrite.write || tokenAssetTransferWrite.isLoading} onClick={() => tokenAssetTransferWrite.write?.()}>
      {tokenAssetTransferWrite.isLoading ?
        <span>Please wait...</span>
      :
        <span>Transfer</span>
      }
    </PurpleButton>
  )
}