import { useDispatch, useSelector } from "react-redux"
import { useAccount, useContractWrite, useNetwork, usePrepareContractWrite, useSwitchNetwork } from "wagmi"
import { amountSelector, ourChainSelector, selectedChainSelector, selectedTokenSelector, setDepositHash } from "../../../store/reducers/transfer"
import styled from 'styled-components';
import { forwardingContractABI } from "../../../contracts/forwadingContract";
import { ethers } from "ethers";
import { useEffect } from "react";
import PurpleButton from "../misc/PurpleButton";
import SwitchNetworkButton from "./SwitchNetworkButton";

export default function TransferButton({ isAmountValid }: any) {
  const selectedChain = useSelector(selectedChainSelector)
  const selectedToken = useSelector(selectedTokenSelector)
  const amount = useSelector(amountSelector)
  const { chain: connectedChain } = useNetwork()
  const { switchNetwork, isLoading: isSwitchingNetwork } = useSwitchNetwork()
  const { address } = useAccount()
  const parsedAmount = ethers.utils.parseUnits(amount || "0", parseInt(selectedToken.precision))
  const tokenAssetTransfer = usePrepareContractWrite({
    address: selectedChain.forwardingContract,
    abi: forwardingContractABI,
    functionName: 'deposit',
    args: [selectedToken.contractAddress, parsedAmount.toString(), address],
    chainId: parseInt(selectedChain.chainId)
  })
  const tokenAssetTransferWrite = useContractWrite({
    ...tokenAssetTransfer.config
  })
  const nativeAssetTransfer = usePrepareContractWrite({
    address: selectedChain.forwardingContract,
    abi: forwardingContractABI,
    functionName: 'depositNative',
    args: [address],
    chainId: parseInt(selectedChain.chainId),
    overrides: {
      value: parsedAmount
    }
  })
  const nativeAssetTransferWrite = useContractWrite({
    ...nativeAssetTransfer.config
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (tokenAssetTransferWrite.data?.hash) {
      dispatch(setDepositHash(tokenAssetTransferWrite.data.hash))
    }

    if (nativeAssetTransferWrite.data?.hash) {
      dispatch(setDepositHash(nativeAssetTransferWrite.data.hash))
    }
  }, [tokenAssetTransferWrite.data, nativeAssetTransferWrite.data])

  if(parseInt(selectedChain.chainId) !== connectedChain?.id) {
    return <SwitchNetworkButton />
  }

  if (!selectedToken.contractAddress) {
    return (
      <PurpleButton disabled={!isAmountValid || !nativeAssetTransferWrite.write || nativeAssetTransferWrite.isLoading} onClick={() => nativeAssetTransferWrite.write?.()}>
        {nativeAssetTransferWrite.isLoading ?
          <span>Please wait...</span>
        :
          <span>Transfer</span>
        }
      </PurpleButton>
    )
  }

  return (
    <PurpleButton disabled={!isAmountValid || !tokenAssetTransferWrite.write || tokenAssetTransferWrite.isLoading} onClick={() => tokenAssetTransferWrite.write?.()}>
      {tokenAssetTransferWrite.isLoading ?
        <span>Please wait...</span>
      :
        <span>Transfer</span>
      }
    </PurpleButton>
  )
}