import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { amountSelector, selectedChainSelector, selectedTokenSelector, setDepositStatus, setTransferAmount, toggleTokenModal } from "../../store/reducers/transfer";
import TokenModal from "./TokenModal";
import BN from 'bignumber.js';
import { erc20ABI, useAccount, useContractRead, useContractWrite, useDisconnect, useNetwork, usePrepareContractWrite, useSwitchNetwork, useWaitForTransaction } from "wagmi";
import SourceNetwork from "./deposit/SourceNetwork";
import TargetNetwork from "./deposit/TargetNetwork";
import { ethers } from "ethers";
import ApproveButton from "./deposit/ApproveButton";
import TransferButton from "./deposit/TransferButton";
import DisconnectButton from "./misc/DisconnectButton";

const Container = styled.div`
  padding-top: 32px;
`

const ArrowDownImage = styled.img`
  display: block;
  margin: 8px auto;
`

const FromLabel = styled.div`
  margin-bottom: 8px;
  font-size: .875rem;
  line-height: 20px;
  font-weight: 700;
`

const ToLabel = styled.div`
  margin-bottom: 8px;
  font-size: .875rem;
  line-height: 20px;
  font-weight: 700;
`

const TransferSection = styled.section`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function DepositStart() {
  const selectedChain = useSelector(selectedChainSelector)
  const selectedToken = useSelector(selectedTokenSelector)
  const { address }: any = useAccount();
  const amount = useSelector(amountSelector)
  const [isAmountValid, setAmountValid] = useState(false);
  const allowance = useContractRead({
    address: selectedToken.contractAddress,
    functionName: 'allowance',
    abi: erc20ABI,
    args: [address, selectedChain.forwardingContract],
    chainId: parseInt(selectedChain.chainId),
    watch: true
  })
  useEffect(() => {
    console.log(amount)
    const parsed = new BN(amount)

    if (parsed.gt(0)) {
      setAmountValid(true)
    } else {
      setAmountValid(false)
    }
  }, [amount])
  const needsApproval = (selectedToken.contractAddress && allowance.data && allowance.data.lt(ethers.constants.MaxUint256)) ? true : false;

  return (
    <Container>
      <FromLabel>From:</FromLabel>
      <SourceNetwork />
      <ArrowDownImage src="/images/arrow-down.svg" />
      <ToLabel>To:</ToLabel>
      <TargetNetwork />
      <TransferSection>
        {needsApproval &&
          <ApproveButton />
        }
        {!needsApproval &&
          <TransferButton isAmountValid={isAmountValid} />
        }
        <DisconnectButton />
      </TransferSection>
      <TokenModal flow="deposit" chain={selectedChain} />
    </Container>
  )
}