import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import Connect from "./Connect";
import Connected from "./Connected";
import { useAccount } from "wagmi";

const Container = styled.div`
  background-color: #fff;
  margin-top: 40px;
  width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 8%);

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;

  h2 {
    font-weight: 600;
    font-size: 1.125rem;
  }
`;

const Networks = styled.div`
  padding: 20px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
`

const NoWallet = styled.div`
  border-top: 1px solid #e7e9ee;
  padding: 32px 10px;
  color: #9fa8ba;
  margin-top: 32px;
  text-align: center;
  font-size: .875rem;
`

const connected = (state: any) => state.auth.metaMask.connected

export default function Bridge() {
  const { address, connector, isConnected } = useAccount()

  if (isConnected == true) {
    return (
      <Container>
        <Connected />
      </Container>
    )
  }

  return (
    <Container>
      <Connect />
    </Container>
  )
}