import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import chainData from '../../assets/tokens.json';
import { amountSelector, ourChainSelector, selectedChainSelector, setTransferAmount, toggleTokenModal } from "../../store/reducers/transfer";
import Loader from "./Loader";
import { selectedWithdrawalTokenSelector } from "../../store/reducers/withdraw";
import TokenModal from "./TokenModal";
import BN from 'bignumber.js';
import { erc20ABI, useAccount, useContractRead, useNetwork } from "wagmi";
import SourceNetwork from "./withdraw/SourceNetwork";
import TargetNetwork from "./withdraw/TargetNetwork";
import { ethers } from "ethers";
import ApproveButton from "./misc/ApproveButton";
import DisconnectButton from "./misc/DisconnectButton";
import TransferButton from "./withdraw/TransferButton";

const Container = styled.div`
  padding-top: 32px;
`

const ArrowDownImage = styled.img`
  display: block;
  margin: 8px auto;
`

const FromLabel = styled.div`
  margin-bottom: 8px;
  font-size: .875rem;
  line-height: 20px;
  font-weight: 700;
`

const ToLabel = styled.div`
  margin-bottom: 8px;
  font-size: .875rem;
  line-height: 20px;
  font-weight: 700;
`

const TransferSection = styled.section`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function WithdrawStart() {
  const { address }: any = useAccount();
  const ourChain = useSelector(ourChainSelector)
  const selectedToken = useSelector(selectedWithdrawalTokenSelector)
  const allowance = useContractRead({
    address: selectedToken.contractAddress,
    functionName: 'allowance',
    abi: erc20ABI,
    args: [address, ourChain.forwardingContract],
    chainId: parseInt(ourChain.chainId),
    watch: true
  })
  const needsApproval = (selectedToken.contractAddress && allowance.data && allowance.data.lt(ethers.constants.MaxUint256)) ? true : false;
  const [isAmountValid, setAmountValid] = useState(false);
  const amount = useSelector(amountSelector)

  useEffect(() => {
    const parsed = new BN(amount)

    if (parsed.gt(0)) {
      setAmountValid(true)
    } else {
      setAmountValid(false)
    }
  }, [amount])

  return (
    <Container>
      <FromLabel>From:</FromLabel>
      <SourceNetwork />
      <ArrowDownImage src="/images/arrow-down.svg" />
      <ToLabel>To:</ToLabel>
      <TargetNetwork />
      <TransferSection>
        {needsApproval ?
          <ApproveButton token={selectedToken} chain={ourChain} />
        :
          <TransferButton isAmountValid={isAmountValid} />
        }
        <DisconnectButton />
      </TransferSection>
      <TokenModal flow="withdraw" chain={ourChain} />
    </Container>
  );

  /* return (
    <Container>
      <FromLabel>From:</FromLabel>
      <FromBox>
        <ChainBalanceContainer>
          <ChainBalanceChain>
            <img src="/images/assets/metal.svg" height={24} />
            <span>Metal Blockchain</span>
          </ChainBalanceChain>
          <ChainBalance>
            <ChainBalance1>Balance:</ChainBalance1>
            {true ?
              <Loader color="purple" height="8px" />
            :
              <ChainBalance2>{sourceChainBalance} {selectedToken.id}</ChainBalance2>
            }
          </ChainBalance>
        </ChainBalanceContainer>
        <ChainInputContainer>
          <ChainInputButton onClick={toggleModal}>
            <ChainInputButtonImage src={selectedToken.image} />
            <ChainInputButtonAssetName>{selectedToken.id}</ChainInputButtonAssetName>
            <ChainInputButtonArrowDown src="/images/discloser-bottom.svg" />
          </ChainInputButton>
          <ChainInputAmountContainer>
            <ChainInputAmountInput value={amount} onChange={(e: any) => updateAmount(e.target.value)} />
            <ChainInputAmountMaxButton onClick={setMaxCallback}>MAX</ChainInputAmountMaxButton>
          </ChainInputAmountContainer>
        </ChainInputContainer>
      </FromBox>
      <ArrowDownImage src="/images/arrow-down.svg" />
      <ToLabel>To:</ToLabel>
      <ToBox>
        <ToBoxChainContainer>
          <ToChainImage src={targetChain.image} />
          <span>{targetChain.name}</span>
        </ToBoxChainContainer>
        <ChainBalance>
            <ChainBalance1>Balance:</ChainBalance1>
            <ChainBalance2>{targetChainBalance} {selectedToken.id}</ChainBalance2>
          </ChainBalance>
      </ToBox>
      <TransferSection>
        <TransferButton disabled={!isAmountValid} onClick={() => {}}>
          {preparing ?
            <Loader color="white" height="12px" />
          :
            <span>Transfer</span>
          }
        </TransferButton>
      </TransferSection>
      <TokenModal flow="withdraw" balances={sourceChainBalances} chain={sourceChain} />
    </Container>
  ) */
}