import { ethers } from "ethers";
import { useSelector } from "react-redux";
import { erc20ABI, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { ourChainSelector, selectedChainSelector, selectedTokenSelector } from "../../../store/reducers/transfer";
import PurpleButton from "../misc/PurpleButton";
import SwitchNetworkButton from "./SwitchNetworkButton";

export default function ApproveButton({ token, chain }: any) {
  const { chain: connectedChain } = useNetwork();
  const { config } = usePrepareContractWrite({
    address: token.contractAddress,
    abi: erc20ABI,
    functionName: 'approve',
    args: [chain.forwardingContract, ethers.constants.MaxUint256],
    chainId: parseInt(chain.chainId)
  })
  const approveContractWrite = useContractWrite(config)
  const approveTransaction = useWaitForTransaction({
    chainId: parseInt(chain.chainId),
    hash: approveContractWrite.data?.hash
  })

  if (token.contractAddress) {
    if(parseInt(chain.chainId) !== connectedChain?.id) {
      return <SwitchNetworkButton chain={chain} />
    }

    return (
      <PurpleButton disabled={!approveContractWrite.write || approveContractWrite.isLoading || approveTransaction.isLoading} onClick={() => approveContractWrite.write?.()}>
        {(approveContractWrite.isLoading || approveTransaction.isLoading) ?
          <>Approving {token.id} transfer</>
        :
          <>Approve {token.id} transfer</>
        }
      </PurpleButton>
    )
  }

  return null;
}