import styled from 'styled-components'

const Button = styled.button`
  background-color: #7b3fe4;
  color: #fff;
  box-shadow: 0 2px 2px -2px rgb(0 0 0 / 8%), inset 0 1px 0.5px hsl(0deg 0% 100% / 16%);
  border-radius: 12px;
  font-size: 1rem;
  line-height: 24px;
  padding: 15px 20px;
  font-weight: 400;
  letter-spacing: normal;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  border: 1px solid hsla(0,0%,100%,.1);
  margin: auto 0;
  outline: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
`

export default function PurpleButton(props: any) {
  return <Button {...props} />
}