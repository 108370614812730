import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { depositHashSelector, depositStatusSelector, selectedChainSelector, setDepositStatus } from "../../store/reducers/transfer";
import DepositStart from "./DepositStart";
import DepositSuccess from "./DepositSuccess";
import DepositPending from "./DepositPending";
import { useWaitForTransaction } from "wagmi";

export default function Deposit() {
  const selectedChain = useSelector(selectedChainSelector)
  const depositHash = useSelector(depositHashSelector)
  const depositStatus = useSelector(depositStatusSelector)
  const x = useWaitForTransaction({
    chainId: parseInt(selectedChain.chainId),
    hash: depositHash
  })
  const dispatch = useDispatch()
  console.log(depositHash)
  
  useEffect(() => {
    dispatch(setDepositStatus(x.status))
  }, [x.status])

  if (depositStatus === 'success') {
    return (
      <DepositSuccess />
    )
  }

  if (depositHash) {
    return (
      <DepositPending />
    )
  }

  return (
    <DepositStart />
  )
}