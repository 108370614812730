import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { resetDeposit, selectedChainSelector } from "../../store/reducers/transfer";
import Lottie from "lottie-react";
import successAnimation from '../../lotties/success.json'
import PurpleButton from "./misc/PurpleButton";
import { resetWithdrawal } from "../../store/reducers/withdraw";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const LoadingContainer = styled.div`
  height: 150px;
  width: 150px;
`

export default function WithdrawSuccess() {
  const dispatch = useDispatch<any>()
  const selectedChain = useSelector(selectedChainSelector)
  const goBack = useCallback(() => {
    dispatch(resetWithdrawal())
  }, [])

  return (
    <Container>
      <h2>Transaction confirmed!</h2>
      <LoadingContainer>
        <Lottie animationData={successAnimation} loop={false} />
      </LoadingContainer>
      <p>Your withdrawal has been confirmed by the network. It may take up to 15 minutes for the coins to reach your {selectedChain.name} wallet.</p>
      <PurpleButton onClick={goBack}>Start over</PurpleButton>
    </Container>
  )
}